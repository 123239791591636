import { url } from "inspector";
import { getMapThumbnail } from "../../../Utilities/Clip";

interface TBuildLink {
  url: string;
  game?: number;
  numClaimableClips?: number;
  username?: string;
  clipThumbnail?: string;
  bestClipId?: string | null;
  loggedInRedirect?: string;
}

export const buildLink = ({
  url,
  game,
  numClaimableClips,
  username,
  clipThumbnail,
  bestClipId,
  loggedInRedirect,
}: TBuildLink) => {
  try {
    if (!window.rudderanalytics.getAnonymousId) return url;
    const anonymousId = window.rudderanalytics.getAnonymousId();
    if (!anonymousId) return url;
    const urlObject = new URL(url);
    urlObject.searchParams.append("ajs_aid", anonymousId);

    if (game) urlObject.searchParams.append("game", game.toString());
    if (numClaimableClips)
      urlObject.searchParams.append("clips", numClaimableClips.toString());
    if (username) urlObject.searchParams.append("username", username);
    if (clipThumbnail)
      urlObject.searchParams.append(
        "clipThumbnail",
        encodeURIComponent(clipThumbnail),
      );
    if (bestClipId) urlObject.searchParams.append("bestClipId", bestClipId);
    if (loggedInRedirect)
      urlObject.searchParams.append("loggedInRedirect", loggedInRedirect);
    return urlObject.toString();
  } catch (e) {
    return url;
  }
};

export const getPartnerAnalyticsId = (partnerId: string) => {
  if (["leetify", "L202211"].includes(partnerId)) return "Leetify";
  if (["gamersclub", "GC2022"].includes(partnerId)) return "Gamersclub";
  if ("SCOPEGG2212" === partnerId) return "Scope.gg";
  if ("ESPORTAL23" === partnerId) return "Esportal";

  return partnerId;
};

export const isLegacyPartner = (partnerId: string) => {
  return [
    "leetify",
    "L202211",
    "gamersclub",
    "GC2022",
    "SCOPEGG2212",
    "ESPORTAL23",
  ].includes(partnerId);
};

export const checkIfClipOwner = (clip: TClip, uid: string) => {
  if ([730, 7302].includes(clip.game)) return uid === clip?.steamId64;
  if ([101].includes(clip.game)) return uid === clip?.username;
  return false;
};

export const getUserDisplayName = (partnerViewSession: any) => {
  if (
    [730, 7302].includes(partnerViewSession?.clip?.game) &&
    partnerViewSession?.isOwnClip
  )
    return partnerViewSession?.clip?.username;
  if ([101].includes(partnerViewSession?.clip?.game))
    return partnerViewSession?.overlayConfig?.uid;
  return "";
};

export const getClaimableClipThumbnail = (clip: TClip) => {
  if (clip.clipImageThumb) return clip.clipImageThumb;

  return getMapThumbnail(clip?.mapName, true);
};

export const getBestClaimableClip = (clips: TClip[], partnerId: string) => {
  const clipsCopyAndFilteredByPartner = [...clips].filter(
    (clip) => clip.partner.partnerId.partnerId === partnerId,
  );

  const sortedByAllstarScore = clipsCopyAndFilteredByPartner.sort(
    (a, b) => b.score.value - a.score.value,
  );

  return sortedByAllstarScore[0];
};

export const isFaceItPartner = (platformId: string) =>
  ["ESLFACEITGROUP", "FACEITLTD", "FACEIT"].includes(platformId);
