import { Box, Flex, Image, Text } from "rebass/styled-components";
import styled from "styled-components";
import { Button } from "MVVM/Components/Button";
import AllstarIcon from "../../../../../assets/images/brand/Wordmark-Light-Tag.png";
import { IoMdCheckmark } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { CLIP_GAME_MAP, URLS } from "MVVM/Utilities/Maps";
import { LoginModalViewModel } from "./ViewModel";
import { IClip } from "MVVM/@types";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export const LoginModal = () => {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    window.rudderanalytics.track("Login Modal", {
      action: "display",
      friendClip: false,
    });
  }, []);

  return (
    <StyledBox>
      <Flex
        flexDirection="column"
        padding={7}
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{ gap: 20 }}
        maxWidth={400}
      >
        <StyledAllstarIcon src={AllstarIcon} />
        <Text variant="text.headingMedium" textAlign={"center"} mb={3}>
          {t("loginModal.title")}
        </Text>
        <List />
        <Button
          mt={3}
          onClick={() => {
            const nextLink = window.location.search
              ? `${window.location.pathname}${window.location.search}`
              : window.location.pathname;
            history.push(`${URLS.LOGIN}?next=${nextLink}`);
            window.rudderanalytics.track("Login Modal", {
              type: "login",
              action: "click",
            });
          }}
        >
          Login
        </Button>

        <Flex style={{ gap: 10 }}>
          <Text variant={"text.labelMedium"}>Don't have an account?</Text>
          <LoginText
            variant={"text.labelMedium"}
            color="envy"
            mr={4}
            onClick={() => {
              window.rudderanalytics.track("Login Modal", {
                type: "signup",
                action: "click",
              });
              history.push(URLS.SIGNUP);
            }}
          >
            Create one
          </LoginText>
        </Flex>
      </Flex>
    </StyledBox>
  );
};

export const FriendClipLoginModal = ({ clip }: { clip?: IClip }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { clipCount } = LoginModalViewModel({
    playerGameId: clip?.playerGameIdentifier,
  });
  useEffect(() => {
    window.rudderanalytics.track("Login Modal", {
      action: "display",
      friendClip: true,
    });
  }, []);

  return (
    <StyledBox>
      <Flex
        flexDirection="column"
        padding={7}
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{ gap: 20 }}
        maxWidth={400}
      >
        <StyledAllstarIcon src={AllstarIcon} />
        <Text variant="text.headingMedium" textAlign={"center"} mb={3}>
          {t("friendClipModal.title")}
        </Text>
        <Text variant="text.paragraphMedium" textAlign={"center"} mb={3}>
          {t("friendClipModal.signupPrompt")}
        </Text>
        <List />
        <Button
          mt={3}
          onClick={() => {
            const nextLink = window.location.search
              ? `${window.location.pathname}${window.location.search}`
              : window.location.pathname;

            window.rudderanalytics.track("Login Modal", {
              friendClip: true,
              type: "signup",
              action: "click",
            });

            history.push(
              `/register?next=${nextLink}${
                clip?.game ? `&game=${CLIP_GAME_MAP[clip?.game]}` : ""
              }${
                clip?.thumbnailStandardUrl
                  ? `&clipThumbnail=${clip?.thumbnailStandardUrl}`
                  : ""
              }&clips=${clipCount}&bestClipId=${clip?.internalId}&utm_source=mh_sharing&utm_medium=clip_upsell_modal`,
            );
          }}
        >
          {t("friendClipModal.signup")}
        </Button>

        <Flex style={{ gap: 10 }}>
          <Text variant={"text.labelMedium"}>Already have an account?</Text>
          <LoginText
            variant={"text.labelMedium"}
            color="envy"
            mr={4}
            onClick={() => {
              window.rudderanalytics.track("Login Modal", {
                friendClip: true,
                type: "login",
                action: "click",
              });
              history.push(URLS.SIGNUP);
            }}
          >
            {t("friendClipModal.login")}
          </LoginText>
        </Flex>
      </Flex>
    </StyledBox>
  );
};
const List = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <StyledCheckmark color="envy" />
        <Text variant={"text.paragraphMedium"}>
          {t("loginModal.postShots")}
        </Text>
      </Row>
      <Row>
        <StyledCheckmark color="envy" />
        <Text variant={"text.paragraphMedium"}>
          {t("loginModal.createReels")}
        </Text>
      </Row>
      <Row>
        <StyledCheckmark color="envy" />
        <Text variant={"text.paragraphMedium"}>
          {t("loginModal.followFriends")}
        </Text>
      </Row>
    </>
  );
};

const LoginText = styled(Text)`
  cursor: pointer;
`;

const StyledCheckmark = styled(IoMdCheckmark)`
  color: ${({ theme }) => theme.colors.envy};
  fill: ${({ theme }) => theme.colors.envy};
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;
const StyledBox = styled(Box)`
  border-radius: 16px;
  mix-blend-mode: normal;
  width: 100%;
  border-color: #233438;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 30px #233438;
  background-color: #10161a;
  max-width: 400px;
`;

const StyledAllstarIcon = styled(Image)`
  width: 110px;
`;

const Row = styled(Flex)`
  align-items: start;
  justify-content: start;
  gap: 15px;
`;
