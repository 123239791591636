import { useLazyQuery, useQuery } from "@apollo/client";
import { NOTIFICATION_MESSAGING } from "MVVM/GraphQL/partnerOverlay";

export const LoginModalViewModel = ({
  playerGameId,
}: {
  playerGameId: string | undefined;
}) => {
  const {
    data,
    loading,
    error: requestError,
  } = useQuery(NOTIFICATION_MESSAGING, {
    variables: {
      steamId: playerGameId,
      puuid: playerGameId,
    },
    skip: !playerGameId,
  });

  return {
    clipCount: data?.suggestedClips?.count,
    loading,
    error: requestError,
  };
};
