import { gql } from "@apollo/client";

export const NOTIFICATION_MESSAGING = gql`
  query SuggestedClips($steamId: String, $partner: String, $puuid: String) {
    suggestedClips(steamId: $steamId, partner: $partner, puuid: $puuid) {
      count
      suggested {
        title
        mapName
      }
      clips {
        _id
        clipTitle
        clipImageThumb
        partner {
          useCase
          partnerId {
            displayName
            partnerId
          }
        }
        score {
          value
        }
      }
    }
  }
`;
