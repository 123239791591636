import { useEffect } from "react";
import { useStatsigClient, useStatsigUser } from "@statsig/react-bindings";

import { usePortal } from "MVVM/Hooks/usePortal";
import {
  FriendClipLoginModal,
  LoginModal,
} from "MVVM/Views/Profile/Components/LogInModal";
import { IClip } from "MVVM/@types";

const isModalCookieSet = () =>
  document.cookie
    .split("; ")
    .some((cookie) => cookie.startsWith("login_modal_shown="));

export const useLoginModal = (allstarUser: any, delay = 5000, clip?: IClip) => {
  const { portalOpen, setPortalOpen, Portal } = usePortal(
    clip?.pointOfView === "OTHER" ? (
      <FriendClipLoginModal clip={clip} />
    ) : (
      <LoginModal />
    ),
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        (Object.keys(allstarUser).length === 0 || !allstarUser.loggedIn) &&
        !isModalCookieSet()
      ) {
        document.cookie = `login_modal_shown=true; max-age=${
          12 * 60 * 60
        }; path=/`;
        setPortalOpen(true);
      }
    }, delay);

    return () => clearTimeout(timeout);
  }, [allstarUser, delay, setPortalOpen]);

  return { portalOpen, setPortalOpen, Portal };
};
