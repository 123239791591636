import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const useIsFirstLoad = () => {
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const hasNavigated = sessionStorage.getItem("hasNavigated");

    if (!hasNavigated) {
      setIsFirstLoad(true);
      sessionStorage.setItem("hasNavigated", "true");
    }

    const unlisten = history.listen(() => {
      sessionStorage.setItem("hasNavigated", "true");
    });

    return () => unlisten();
  }, [history]);

  return isFirstLoad;
};

export { useIsFirstLoad };
