import { useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Box, Flex } from "rebass/styled-components";
import styled, { css } from "styled-components";
import videojs from "video.js";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

import { ClipExperienceViewModel } from "./ViewModel";
import { VideoPlayer } from "MVVM/VideoPlayer";
import { countClipImpression } from "MVVM/Utilities/Clip";
import CloseButton from "components/atoms/form/button/close";
import { Button } from "MVVM/Components/Button";
import { EClipPageSource } from "MVVM/@types";
import { ClipPruned } from "MVVM/Components/Clip/Components/ClipPruned";
import { TransitTime } from "MVVM/Components/TransitTime";
import { useLoginModal } from "MVVM/Hooks/useLoginModal";
import { useUser } from "MVVM/Hooks/useUser";
import { analytics } from "MVVM/Analytics";
import { Sidebar } from "./Sidebar";
import { Missing } from "./Missing";
import { getQueryParam } from "MVVM/Utilities/QueryParam";
import { useIsFirstLoad } from "MVVM/Hooks/useIsFirstLoad";

interface ClipExperienceProps {
  clipId?: string;
  source: EClipPageSource;
}

const ClipExperience = ({ clipId = "", source }: ClipExperienceProps) => {
  const { id } = useParams<{ id: string }>();
  const mobileView = getQueryParam("mobileView");
  const videoPlayerRef = useRef<videojs.Player | null>(null);
  const history = useHistory();
  const location = useLocation<{
    autoplay: boolean | undefined;
    referrer: string | undefined;
  }>();
  const isModal = source === EClipPageSource.MODAL;
  const autoplay = location.state?.autoplay;
  const referrer = location.state?.referrer;
  const { data, error, loading, createPrunedClip } = ClipExperienceViewModel({
    id: id || clipId,
  });
  const { allstarUser } = useUser();
  const isFirstLoad = useIsFirstLoad();

  const { portalOpen, Portal } = useLoginModal(allstarUser, 5000, data?.clip);

  if (loading) return <></>;
  if (
    error ||
    !data.clip ||
    ["Deleted", "Inactive"].includes(data.clip?.status)
  ) {
    window.rudderanalytics.track("Clip Experience - Missing");
    return <Missing />;
  }

  window.history.replaceState({}, "");

  const { clip } = data;

  return (
    <ClipWrapper
      isModal={isModal}
      style={{
        backgroundImage: `url(${clip?.thumbnailStandardUrl})`,
      }}
    >
      {portalOpen && Portal}
      {[EClipPageSource.FEED, EClipPageSource.DIRECT].includes(source) &&
        !isFirstLoad && (
          <StyledCloseButton
            size="small"
            classification="round"
            onClick={() => {
              analytics.track("Clip Page - Close Button Clicked", {});
              referrer ? history.push(referrer) : history.goBack();
            }}
          />
        )}
      <ClipContainer isModal={isModal}>
        <VideoContainer isModal={isModal}>
          <VideoWrapper mobileView={!!mobileView}>
            {[EClipPageSource.FEED].includes(source) && (
              <>
                <StyledButtonUp
                  size="small"
                  classification="round"
                  onClick={history.goBack}
                  flex={true}
                >
                  <FaChevronUp />
                </StyledButtonUp>
                <StyledButtonDown
                  size="small"
                  classification="round"
                  onClick={history.goBack}
                  flex={true}
                >
                  <FaChevronDown />
                </StyledButtonDown>
              </>
            )}
            {["ON_DEMAND", "PRUNED"].includes(clip.status) && (
              <Flex
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <ClipPruned
                  onClick={() => {
                    createPrunedClip({
                      variables: {
                        clipIdentifier: clip.shareId,
                      },
                    });
                  }}
                  clipTitle={clip.title}
                  onDemand={clip.status === "ON_DEMAND"}
                />
              </Flex>
            )}
            {clip.status === "PENDING" && (
              <Flex
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                {clip.type === "CAREER_MONTAGE" ? (
                  <CareerMontagePending>
                    Career Montage is processing...
                  </CareerMontagePending>
                ) : (
                  <TransitTime clip={clip.shareId} />
                )}
              </Flex>
            )}
            {clip.status === "READY" && (
              <VideoPlayer
                mp4Source={mobileView ? clip.portraitUrl : clip.url}
                refForVideoPlayer={videoPlayerRef}
                onPlayStart={() => countClipImpression(clip.shareId)}
                loop={true}
                playAnalyticsProperties={{
                  shareId: clip.shareId,
                  clipId: clip._id,
                  clipOwner: clip.user,
                  total_views: clip.views,
                  game: clip.game,
                }}
                watchAnalyticsProperties={{
                  collection: "Clip Experience",
                  collectionType: "Generic",
                  item: clip.shareId,
                  itemType: "Clip",
                  action: "watch",
                }}
                options={{
                  autoplay: autoplay ? "play" : "muted",
                  controls: true,
                  fluid: true,
                }}
              />
            )}
          </VideoWrapper>
        </VideoContainer>
        <Sidebar clip={clip} source={source} referrer={referrer} />
      </ClipContainer>
    </ClipWrapper>
  );
};

const VideoWrapper = styled(Box)<{ mobileView?: boolean }>`
  position: relative;

  ${(props) =>
    props.mobileView
      ? `
        aspect-ratio: 9/16;
        height: 100%;
        max-height: fit-content;
        margin: 0 auto;
        width: auto;

        .video-js {
          aspect-ratio: 9/16;
          height: 100% !important;
          max-height: calc(100vh - 75px) !important;
          width: auto !important;

          @media (max-width: ${props.theme.breaks.theater}) {
            height: 60vh !important;
          }
        }

        video {
          object-fit: contain !important;
        }
        `
      : `
        height: auto;
        width: 100%;
      `}
`;

const CareerMontagePending = styled(Box)`
  background: ${({ theme }) => theme.colors.ned};
  border: 1px solid ${({ theme }) => theme.colors.chalkAlpha20};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.ice};
  padding: 20px;
`;

const ClipWrapper = styled(Box)<{ isModal?: boolean }>`
  background-size: cover;
  height: 100%;
  max-height: calc(100vh - 73px);
  overflow: hidden;
  position: relative;
  scrollbar-width: none;
  width: 100%;

  ${(props) => (props.isModal ? "border-top-left-radius: 8px;" : "")}
  ${(props) => (props.isModal ? "border-bottom-left-radius: 8px;" : "")}

  @media (max-width: ${(props) => props.theme.breaks.theater}) {
    background-color: ${({ theme }) => theme.colors.darkerNed};
    background-image: none !important;
    overflow: auto;
  }
`;

const ClipContainer = styled(Flex)<{ isModal?: boolean }>`
  backdrop-filter: blur(14px);
  height: 100%;
  width: 100%;

  ${(props) => (props.isModal ? "border-top-left-radius: 8px;" : "")}
  ${(props) => (props.isModal ? "border-bottom-left-radius: 8px;" : "")}

  @media (max-width: ${(props) => props.theme.breaks.theater}) {
    flex-direction: column;
    height: auto;
  }
`;

const roundButtonStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 50%;
  opacity: 0.8;
  position: absolute;
  transform: translate(-50%, 0%);
  z-index: 2;

  :hover {
    background: ${({ theme }) => theme.colors.darkerNed};
    color: ${({ theme }) => theme.colors.ice};
    opacity: 0.6;
  }
`;

const StyledButtonUp = styled(Button)`
  ${roundButtonStyles}
  top: 12px;
`;

const StyledButtonDown = styled(Button)`
  ${roundButtonStyles}
  bottom: 12px;
`;

const StyledCloseButton = styled(CloseButton)`
  left: 12px;
  position: absolute;
  top: 12px;
  z-index: 2;

  :hover {
    background: ${({ theme }) => theme.colors.darkerNed};
    color: ${({ theme }) => theme.colors.ice};
  }

  @media (max-width: ${(props) => props.theme.breaks.theater}) {
    display: none;
  }
`;

const VideoContainer = styled(Flex)<{ isModal?: boolean }>`
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  position: relative;

  ::before {
    background-color: rgba(0, 0, 0, 0.6);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;

    ${(props) => (props.isModal ? "border-top-left-radius: 8px;" : "")}
    ${(props) => (props.isModal ? "border-bottom-left-radius: 8px;" : "")}
  }
`;

export { ClipExperience };
