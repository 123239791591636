import { useQuery } from "@apollo/client";

import { CLIP_COMMENTS } from "MVVM/GraphQL/clipComments";

interface IClipCommentsViewModelProps {
  clipId: string;
}

const ClipCommentsViewModel = ({ clipId }: IClipCommentsViewModelProps) => {
  const { data, error, loading } = useQuery(CLIP_COMMENTS, {
    variables: {
      id: clipId,
    },
    fetchPolicy: "cache-and-network",
  });

  return {
    data,
    error,
    loading,
  };
};

export { ClipCommentsViewModel };
