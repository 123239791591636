import {
  useEffect,
  useRef,
  MutableRefObject,
  useCallback,
  useState,
} from "react";
import { Box } from "rebass/styled-components";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { StyledPlayer } from "./styledComponents";
import { analytics } from "../Analytics";

export type TSourceType = { src: string; type: string };
interface IVideoPlayerProps {
  mp4Source?: string;
  hlsSource?: string;
  watchAnalyticsProperties?: Record<string, unknown>;
  playAnalyticsProperties?: Record<string, unknown>;
  options?: Omit<videojs.PlayerOptions, "loop">;
  refForVideoPlayer: MutableRefObject<videojs.Player | null>;
  onPlayStart?: () => void;
  loop?: boolean; // @IMPORTANT use this to loop instead of options.loop
}

export const VideoPlayer = ({
  mp4Source,
  hlsSource,
  options,
  refForVideoPlayer,
  onPlayStart,
  watchAnalyticsProperties,
  playAnalyticsProperties,
  loop,
  ...props
}: IVideoPlayerProps) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<videojs.Player | null>(null);
  const [secondsWatched, setSecondsWatched] = useState(0);
  const [trackedView, setTrackedView] = useState(false);
  const interval = useRef<NodeJS.Timeout | undefined>();

  const startTrackingSecondsWatched = useCallback(() => {
    if (!interval.current) {
      interval.current = setInterval(() => {
        setSecondsWatched((prev) => prev + 1);
      }, 1000);
    }
  }, [interval]);

  const stopTrackingSecondsWatched = useCallback(() => {
    clearInterval(interval.current);
    interval.current = undefined;
  }, [interval]);

  useEffect(() => {
    if (!playerRef.current && videoRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      playerRef.current = videojs(videoElement, {
        controls: true,
        fluid: true,
        sources: [],
        ...options,
      });
    }

    if (refForVideoPlayer) {
      refForVideoPlayer.current = playerRef.current;
    }
  }, [options, refForVideoPlayer]);

  useEffect(() => {
    const player = playerRef.current;
    if (!player) return;

    let source: TSourceType | null = null;

    if (mp4Source) {
      source = { src: mp4Source, type: "video/mp4" };
    } else if (hlsSource) {
      source = { src: hlsSource, type: "application/x-mpegURL" };
    }

    if (source) {
      player.src(source);
      player.load();
    }
  }, [mp4Source, hlsSource]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const player = playerRef.current;
    const onPlay = () => {
      const playerVolume = localStorage.getItem("playerVolume");

      startTrackingSecondsWatched();

      if (!player?.currentTime() && onPlayStart) onPlayStart();
      if (playerVolume) player?.volume(parseFloat(playerVolume));

      window.rudderanalytics.track("Video Player - Play", {
        ...playAnalyticsProperties,
        total_length: player?.duration(),
        file_extension: player?.currentSrc().split(".").pop(),
      });
    };

    player?.on("play", onPlay);

    return () => player?.off("play", onPlay);
  }, [onPlayStart, playAnalyticsProperties, startTrackingSecondsWatched]);

  useEffect(() => {
    const player = playerRef.current;
    const onPause = () => stopTrackingSecondsWatched();

    player?.on("pause", onPause);

    return () => player?.off("pause", onPause);
  }, [stopTrackingSecondsWatched]);

  useEffect(() => {
    const player = playerRef.current;
    const onVolumeChange = () => {
      // We only want to set volume if the user intended to change it, not via autoplay settings
      if (options?.autoplay === "muted") return;

      localStorage.setItem(
        "playerVolume",
        player?.muted() ? "0.0" : `${player?.volume()}`,
      );
    };

    player?.on("volumechange", onVolumeChange);

    return () => player?.off("volumechange", onVolumeChange);
  }, [options?.autoplay]);

  useEffect(() => {
    const player = playerRef.current;
    const onEnded = () => {
      if (loop) {
        setSecondsWatched(0);
        setTrackedView(false);
        playerRef?.current?.play();
      }
    };

    player?.on("ended", onEnded);

    return () => player?.off("ended", onEnded);
  }, [loop, playerRef]);

  useEffect(() => {
    if (secondsWatched >= 5 && !trackedView) {
      analytics.track("Video Player - Watched 5 seconds", {
        ...watchAnalyticsProperties,
      });
      setTrackedView(true);
      stopTrackingSecondsWatched();
    }
  }, [
    secondsWatched,
    stopTrackingSecondsWatched,
    trackedView,
    watchAnalyticsProperties,
  ]);

  return (
    <StyledPlayer>
      <Box data-vjs-player {...props}>
        <Box ref={videoRef} />
      </Box>
    </StyledPlayer>
  );
};
