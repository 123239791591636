import { gql } from "@apollo/client";

const CLIP_USER_FIELDS = gql`
  fragment ClipUserFragment on User {
    _id
    avatar
    avatarUrl
    commentsDisabled
    discord {
      id
      avatar
      username
    }
    rank
    userGroup {
      type
    }
    username
  }
`;

export const GET_CLIP = gql`
  ${CLIP_USER_FIELDS}

  query Clip($id: String!) {
    clip(id: $id) {
      id
      bytes
      canRequestPortrait
      createdAt
      game
      hasPortrait
      internalId
      isRemixable
      shareId
      status
      thumbnailOverlayUrl: thumbnailUrl(style: OVERLAY)
      thumbnailStandardUrl: thumbnailUrl(style: STANDARD)
      title
      type
      url(orientation: LANDSCAPE)
      portraitUrl: url(orientation: PORTRAIT)
      views
      pointOfView
      playerGameIdentifier
      campaign {
        isActive
        marketingCopyClip
      }

      effects {
        id
        internalId
        title
        types
      }

      players {
        avatar
        displayName
        gameId

        user {
          ...ClipUserFragment @nonreactive
        }
      }

      reactions {
        count
        type
      }

      user {
        ...ClipUserFragment @nonreactive
      }
    }
  }
`;
