import { Flex } from "rebass/styled-components";
import styled from "styled-components";

import { LoadingSpinner } from "MVVM/Components/Lottie";
import { EmptyList } from "MVVM/Views/ClipExperience/EmptyList";
import { ClipCommentsViewModel } from "./ViewModel";
import { ClipComment } from "./ClipComment";
import { IClipComment } from "./@types";
import { ClipCommentForm } from "./ClipCommentForm";

interface IClipCommentsProps {
  clipId: string;
  disabled?: boolean;
  setCommentCount?: (arg0: number) => void;
}

const ClipComments = ({
  clipId,
  disabled,
  setCommentCount,
}: IClipCommentsProps) => {
  const { data, error, loading } = ClipCommentsViewModel({ clipId });

  if (loading)
    return (
      <Flex justifyContent="center" alignItems="center" mt={80}>
        <LoadingSpinner height={20} width={20} />
      </Flex>
    );
  if (!data || error) return <></>;

  const comments = data?.clip?.comments?.nodes;

  setCommentCount && setCommentCount(comments?.length || 0);

  return (
    <ClipCommentsWrapper>
      <ClipCommentsContainer>
        {disabled ? (
          <EmptyList text="Comments are disabled" />
        ) : comments?.length ? (
          comments.map((comment: IClipComment) => (
            <ClipComment key={comment.id} comment={comment} />
          ))
        ) : (
          <EmptyList text="No comments posted yet" />
        )}
      </ClipCommentsContainer>
      {!disabled && <ClipCommentForm clipId={clipId} />}
    </ClipCommentsWrapper>
  );
};

const ClipCommentsWrapper = styled(Flex)`
  flex-direction: column;
  height: 100%;

  @media (max-width: ${(props) => props.theme.breaks.theater}) {
    flex-direction: column-reverse;
  }
`;

const ClipCommentsContainer = styled(Flex)`
  background: ${({ theme }) => theme.colors.darkerNed};
  flex-direction: column;
  height: 100%;
  margin-bottom: auto;
  overflow: auto;
  padding: 12px;
  width: 100%;
`;

export { ClipComments };
