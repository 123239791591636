import { gql } from "@apollo/client";

const CLIP_COMMENT_FIELDS = gql`
  fragment ClipCommentFields on Comment {
    id
    _id
    createdAt
    deletedAt
    text

    clip: newClip {
      id
      _id: internalId
    }

    user {
      _id
      avatarUrl: avatar
      rank
      username
    }
  }
`;

const CLIP_COMMENTS = gql`
  ${CLIP_COMMENT_FIELDS}

  query ClipComments($id: String!) {
    clip(id: $id) {
      id
      comments {
        nodes {
          ...ClipCommentFields
        }
      }
    }
  }
`;

const CLIP_COMMENT_CREATE = gql`
  ${CLIP_COMMENT_FIELDS}

  mutation ClipCommentCreate($clipId: String!, $text: String!) {
    commentCreate(input: { clipId: $clipId, text: $text }) {
      errors {
        message
      }
      success
      comment {
        ...ClipCommentFields
      }
    }
  }
`;

const DELETE_COMMENT = gql`
  ${CLIP_COMMENT_FIELDS}

  mutation ClipCommentDelete($commentId: String!) {
    commentDelete(input: { commentId: $commentId }) {
      errors {
        message
      }
      success
      comment {
        ...ClipCommentFields
      }
    }
  }
`;

const DISABLED_COMMENTS = gql`
  mutation DisableComments {
    toggleCommentsUser {
      success
    }
  }
`;

const REPORT_COMMENT = gql`
  mutation CommentReport($commentId: String!) {
    commentReport(commentId: $commentId) {
      success
    }
  }
`;

export {
  CLIP_COMMENTS,
  CLIP_COMMENT_CREATE,
  CLIP_COMMENT_FIELDS,
  DELETE_COMMENT,
  DISABLED_COMMENTS,
  REPORT_COMMENT,
};
